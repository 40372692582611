<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Obtained Marks *"
              invalid-feedback="marks is required."
              ref="marks"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter obtained marks here"
                ref="marks"
                v-model="marksObj.obtained_marks"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Result Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Show Maximum Rank"
              invalid-feedback="Maximum rank is required."
              ref="maxnum"
            >
              <b-form-input
                type="number"
                id="mc-first-name"
                placeholder="Enter max ranks to show"
                ref="maxnum"
                v-model="maxObj.number"
                @input="CheckNum()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="saveSetting()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="start" class="text-left mt-1">
        <b-col md="2">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              input-id="id"
              ref="class"
              @input="FillSections()"
              @search:blur="LoadSubjects()"
              :disabled="dataLoading"
              placeholder="Select class."
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <v-select
              ref="sec"
              v-model="myObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select section."
              @input="LoadSubjects()"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label="Session"
            invalid-feedback="Session is required."
            ref="session"
          >
            <v-select
              ref="session"
              v-model="myObj.sessionID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sessions"
              :reduce="(val) => val.id"
              label="session"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select session."
              @input="LoadSubjects()"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label="Exam Type"
            invalid-feedback="Exam type is required."
            ref="examType"
          >
            <v-select
              ref="examType"
              v-model="myObj.examID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="examType"
              :reduce="(val) => val.id"
              label="examType"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select exam type."
              @input="LoadSubjects()"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col md="2">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="subject"
          >
            <v-select
              ref="subject"
              v-model="myObj.subID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subjects"
              :reduce="(val) => val.id"
              label="subject"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select subject"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col md="2">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              :config="config"
              ref="date"
              v-model="myObj.date"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col> -->
        <b-col md="2" class="mt-2 p-0">
          <b-button
            style="width: 70%"
            variant="success"
            class="mr-1"
            @click="show()"
            :disabled="request"
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-else> Show </span>
          </b-button>
          <b-spinner v-if="settLoading" small type="grow" />
          <feather-icon
            v-else
            class="cursor-pointer"
            icon="SettingsIcon"
            size="26"
            v-b-tooltip.hover.right
            title="Settings"
            @click="openSettings()"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row class="match-height" v-if="!showDetils">
      <div
        style="display: flex; justify-content: center; width: 100%"
        v-if="subLoading"
      >
        <b-spinner
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
      <b-col
        v-else
        xl="3"
        lg="3"
        md="4"
        v-for="(sub, ind) in subjects"
        :key="sub"
      >
        <b-row style="width: 100%" class="mb-2">
          <b-col xl="3" lg="3" md="4">
            <b-card
              class="d-flex align-items-center justify-content-center"
              style="height: 100%"
              align-v="center"
              :bg-variant="color[ind]"
              no-body
            >
              <b-card-title class="m-0" style="color: white">{{
                sub.subjectName.charAt(0)
              }}</b-card-title>
              <!-- <h3></h3> -->
            </b-card>
          </b-col>
          <b-col xl="9" lg="9" md="8" class="pl-0">
            <h3>{{ sub.subjectName }}</h3>
            <h5
              class="mb-0"
              :class="sub.isMarked ? 'text-success' : 'text-danger'"
            >
              {{ sub.isMarked ? "Marked" : "Unmarked" }}
            </h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card v-if="showGrid" class="mt-2">
      <b-row>
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <b-card-title class="m-0">Result</b-card-title>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="Print()"
          >
            <feather-icon icon="PrinterIcon" class="" />
            Print
          </b-button> -->
        </div>

        <b-table
          class="mt-1"
          ref="selectableTable"
          :items="items"
          :fields="fields"
          show-empty
          :busy="gridLoading"
          responsive
          hover
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #cell(name)="data">
            <b-badge style="margin-inline: 2px" variant="light-primary">
              {{ data.item.name }}
            </b-badge>
          </template>

          <template #head(actions)="data">
            <div class="mr-5 text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="ViewDetails(data.item)"
              >
                <feather-icon icon="EyeIcon" class="" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Edit(data.item)"
              >
                <feather-icon icon="EditIcon" class="" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Print()"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-card v-if="showDetils" class="mt-1">
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="Back()"
          >
            <feather-icon icon="ChevronLeftIcon" />
            Back
          </b-button>
        </b-col>
      </b-row>
      <b-card-title class="mt-1">Result</b-card-title>

      <chartjs-component-bar-chart
        :height="400"
        :data="barchartOptions.data"
        :options="barchartOptions.options"
        :plugins="plugins"
      />
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ChartjsComponentBarChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";

import { mapActions, mapGetters } from "vuex";

import { $themeColors } from "@themeConfig";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    //Multiselect,
    ChartjsComponentBarChart,
    BCard,
    BCardTitle,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.details.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      request: false,
      saving: false,
      settLoading: false,
      colorDep: [],
      visibility2: false,
      color: [
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
      ],

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        { label: "marks", key: "marks" },
        { label: "percentage", key: "percentage" },
        { label: "grade", key: "grade" },
        // { key: "actions", label: "actions" },
      ],
      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],
      sections: [],
      secText: "Section",
      classes: [],
      classesOptions: [],
      currentSections: [],
      subjects: [],
      classText: "Class",
      sessions: [],
      sessionText: "Session",
      examType: [],
      examText: "Exam Type",
      selected: null,
      rangeDate: null,
      myObj: {
        clsID: 0,
        secID: 0,
        examID: 0,
        sessionID: 0,
        date: null,
        subID: 0,
      },

      marksObj: {},
      maxObj: {
        number: 3,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      showGrid: false,
      showDetils: false,
      subLoading: false,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      barchartOptions: {
        data: {
          labels: [
            "Computer",
            "Chemistry",
            "Mathematics",
            "Biology",
            "Islamic History",
            "English",
            "Urdu",
          ],
          datasets: [
            {
              label: "Marks",
              data: ["66", "75", "70", "89", "67", "52", "91"],
              backgroundColor: $themeColors.success,
              borderColor: "transparent",
            },
            // {
            //   label: "Girls",
            //   data: ["18", "44", "30", "24", "75", "50", "52", "71"],
            //   backgroundColor: "#00cfe8",
            //   borderColor: "transparent",
            // },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 5,
              borderSkipped: "bottom",
              barThickness: 40,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: "top",
            align: "end",
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 12,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 20,
                  min: 0,
                  max: 100,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async Edit(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "examtypes/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      //   console.log("editObj:", this.myObj);
      this.marksObj = item;
      this.visibility = true;
      this.sidebarTitle = "Edit Marks";
      this.sidebarButton = "Update";
      var elem = this.$refs["marks"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        account: 0,
        balance: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add balance";
      this.sidebarButton = "Save";
      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },

    CheckMarks() {
      var elem = this.$refs["marks"];
      var re = /^[0-9]+$/;
      if (re.test(this.marksObj.obtained_marks)) {
        this.marksObj.obtained_marks = parseInt(this.marksObj.obtained_marks);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    FillSections() {
      // console.log(this.myObj.clsID);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.clsID) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      this.myObj.secID = 0;
      // console.log("Thisis", this.currentSections);
    },
    ViewDetails(item) {
      this.showGrid = false;
      this.showDetils = true;
    },

    Back() {
      this.showDetils = false;
      this.showGrid = true;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = [];
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      // console.log("item", this.classes);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj2);
      // console.log("Session", this.sessions);

      var obj3 = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.examType = await this.get(obj3);
      // console.log("exam type", this.examType);

      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Subjects?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.subjects = await this.get(obj);

      // console.log("subject", this.subjects);

      this.dataLoading = false;
    },
    async LoadSubjects() {
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.subLoading = true;
        this.subjects = await this.post({
          url:
            this.$store.state.domain +
            "Marks/LoadSubjects?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.myObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.subjects);
        this.subLoading = false;
      }
    },
    async show() {
      // console.log(this.myObj);
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.showGrid = true;
      } else {
        this.$bvToast.toast("Please select all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    openSettings() {
      this.visibility2 = true;
    },
    CheckNum() {
      var elem = this.$refs["maxnum"];
      var re = /^[0-9]+$/;
      if (re.test(this.maxObj.number) && this.maxObj.number != 0) {
        this.maxObj.number = parseInt(this.maxObj.number);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    saveSetting() {
      if (this.CheckNum() == true) {
        this.saving = true;
        console.log(this.maxObj);
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain + " ?db=" + this.$store.state.userData.db,
        //   body: this.maxObj,
        //   message: "Setting saved successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        this.saving = false;

        // if (status) this.visibility2 = false;
      }
    },
    async Add() {
      //   this.CheckAccount();
      this.CheckMarks();
      if (this.CheckMarks() == false) {
        return this.$bvToast.toast("Please enter the marks.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.marksObj.id == 0) {
          //Add
          this.request = true;
          console.log("obj:", this.marksObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.post({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Marks added successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          console.log("edit_obj:", this.marksObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.put({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance/" +
          //       this.myObj.id +
          //       "?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Marks updated successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   console.log("status", status);
          //   if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "OpeningBalance/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   body: null,
        //   message: "Opening Balance removed successfully.",
        //   context: this,
        // });
        // if (status) this.LoadData();
        console.log("balance deleted");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
